import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';
import Container from 'components/Container';
import { useAuth } from 'contexts/AuthContext';
import { media } from 'utils/media';

type SingleFooterListItem = { title: string; href: string };
type FooterListItems = SingleFooterListItem[];
type SingleFooterList = { title: string; items: FooterListItems };
type FooterItems = SingleFooterList[];

const footerItems: FooterItems = [
  {
    title: 'Como funciona',
    items: [{ title: 'Perguntas frequentes', href: '/faq' }],
  },
  {
    title: 'Contato',
    items: [{ title: 'Fale conosco', href: '/contact' }],
  },
  {
    title: 'Institucional',
    items: [
      { title: 'Sobre nós', href: '/explore' },
      { title: 'Termos de uso', href: '/termos-e-condicoes' },
      { title: 'Política de privacidade', href: '/privacy-policy' }
    ],
  },
  {
    title: 'Redes',
    items: [
      { title: 'Instagram', href: 'https://www.instagram.com/nossavaquinha/' }, 
    ],
  },
];

export default function Footer() {
  const { user } = useAuth();
  const router = useRouter();
  const pathUrl = router.asPath;

  const isLogado =
    user.id && pathUrl !== '/' && pathUrl !== '/blog' && pathUrl !== '/about' && pathUrl !== '/faq' && pathUrl !== '/contact'
      ? true
      : false;


  let date = new Date();
  let year = date.getFullYear();

  return (
    <FooterWrapper colorTheme={'#4397c3'} isLogado={isLogado}>
      <Container>
        <ListContainer>
          <Logo>
            <img src="/Logo.svg" alt="Selo Google" className="google" />
            <p className="web-copyright">© {year} - Todos os direitos reservados.</p>

            <p>CNPJ: 48.928.262/0001-47 <br /> Razão social: Meu Nascimento LTDA</p>
          </Logo>

          {footerItems.map((singleItem) => (
            <FooterList key={singleItem.title} {...singleItem} />
          ))}
          <Pag>
            <PaymentStickers>
              <img src="/selos-pagamento/selo-google.svg" alt="Selo Google" className="google" />
              <img src="/selos-pagamento/selo-pagarme.svg" alt="Selo Pagarme" className="pagarme" />
              <img src="/selos-pagamento/ssl.svg" alt="Selo SSL" className="ssl" />
            </PaymentStickers>
          </Pag>
        </ListContainer>
      </Container>
     
    </FooterWrapper>
  );
}

function FooterList({ title, items }: SingleFooterList) {
  return (
    <ListWrapper>
      <ListHeader>{title}</ListHeader>
      {items.map((singleItem) => (
        <ListItem key={singleItem.href} {...singleItem} />        
      ))}
    </ListWrapper>
  );
}

function ListItem({ title, href }: SingleFooterListItem) {
  const { pathname } = useRouter();

  return (
    <ListItemWrapper colorTheme={'#4397c3'}>
      <NextLink href={href} passHref>
        <a href="https://github.com">{title}</a>
      </NextLink>
    </ListItemWrapper>
  );
}
interface IThemeProps {
  colorTheme: string;
  isLogado?: boolean;
}
const FooterWrapper = styled.div<IThemeProps>`
  padding-top: 10rem;
  padding-bottom: 4rem;
  background: #FBF9ED;
  color: ${({ colorTheme }) => (colorTheme === '#fff6e8' ? '#4397c3' : '#fff')};
  box-shadow: 0px 0px 0px #d3d3d3, 0px -1px 6px #d3d3d3;  
  color:  #174F68;

  h3 {
    text-align: center;
    margin-top: 5px;
  }

  .mobile-copyright {
    display: none;
  }

  @media (max-width: 478px) {
    h3 {
      margin-top: 15px;
    }
    
    .mobile-copyright {
      display: inline-block;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      margin-left: 85px;
      }
    }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Logo = styled.div`
  display: block;

  p {
    margin-top: 3rem;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;

  }

  img {
    width: 90%;
  }

  @media (max-width: 600px) {
    img {
      margin-top: -5rem;
      margin-bottom: 4rem;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 478px) {
    p {
      display: none;
    }
  }

  p {
    font-size: 14px !important;
  }

  @media (max-width: 820px) {
    .mobile-copyright {
      display: block;
    }
    .web-copyright {
      display: none;
    }
  }
`;

const Pag = styled.div`
  img {
    width: 100%;
    height: 85%;
  }
  
  @media (max-width: 600px) {
    
    img {
      margin-top: -5rem;
      margin-bottom: 4rem;
      width: 90%;
    }
  }
`;

const ListHeader = styled.p`
  font-weight: bold;
  font-size: 2.25rem;
  margin-bottom: 1rem;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  margin-right: 5rem;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }
  ${media('<=tablet')} {
    flex: 40%;
    margin-right: 1.5rem;
  }
  ${media('<=phone')} {
    margin-left: 6rem;
    flex: 50% 50%;
    margin-right: 0rem;
  }
`;

const ListItemWrapper = styled.p<IThemeProps>`
  font-size: 1.6rem;
  
  a {
    text-decoration: none;
    
    color: #174F68;
  }
`;

const PaymentStickers = styled.div`
  img {  
    width: 120px; 
    object-fit: contain;
    padding-bottom: 1.5rem;
  }
  @media (max-width: 600px) {
    img {
      margin-bottom: 70px;
    }
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 5rem;
    flex-wrap: wrap;

    width: 100%;
    & > *:not(:first-child) {
      margin-left: 1rem;
    }
  }
`;